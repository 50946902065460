<script>
export default {
  data() {
    return {
      list: [],
    }
  },
  mounted() {
    this.getRecordList()

  },
  methods: {
    back() {
      return window.history.back();
    },
    formatTimestamp(ts) {
      const date = new Date(ts * 1000);
      const Y = date.getFullYear();
      const M = String(date.getMonth() + 1).padStart(2, '0');
      const D = String(date.getDate()).padStart(2, '0');
      const h = String(date.getHours()).padStart(2, '0');
      const m = String(date.getMinutes()).padStart(2, '0');
      const s = String(date.getSeconds()).padStart(2, '0');
      return `${Y}-${M}-${D} ${h}:${m}:${s}`;
    },
    getRecordList() {
      this.$http({
        method: 'get',
        url: 'recharge_log'
      }).then(res => {
        console.log(res.data);
        if (res.code === 200) {
          console.log(res.data)
          this.list = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    }
  }
}
</script>

<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('recharge.recharge')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
    </div>
    <div class="main">
      <div class="item_list" v-for="item in list" :key="item.id">
        <div class="topInfo"><span>金额：{{ item.money }}</span></div>
        <div class="desc"><span>說明：{{ item.desc }}</span></div>
        <div class="time"><span>提交時間：{{ formatTimestamp(item.create_time) }}</span></div>
        <div class="time"><span>稽核時間：{{ formatTimestamp(item.create_time) }}</span></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.item_list {
  padding: 2vw 2vw;
  margin: 4vw 1.333vw;
  background: #fff;
  border-radius: 1.333vw;
  line-height: 8vw;
}

.item_list .topInfo span {
  flex: 1;
  font-size: 4.667vw;
  font-weight: 700;
  color: #ff253f;
}

.item_list .desc span {
  font-size: 3.333vw;
  font-weight: 700;
  color: #9b9b9b;
}

.item_list .time span {
  flex: 1;
  font-size: 3.333vw;
  font-weight: 500;
  color: #000;
}

.item_list .topInfo span:last-child {
  float: right;
}
</style>
