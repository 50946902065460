<template>
  <div class="container page">
    <van-nav-bar :title="$t('setting.modify_real_name')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
      <template #right>
        <span class="nav-right" @click="save()">{{ $t("setting.save") }}</span>
      </template>
    </van-nav-bar>
    <van-cell-group>
      <van-field v-model="name" :readonly="userInfo.name!=null&&userInfo.name !== ''" :label="$t('setting.name')"
                 :placeholder="$t('setting.name_place')"/>
    </van-cell-group>
    <p>{{ $t("setting.name_tip") }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      userInfo: {}
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    isTraditionalChinese(str) {
      // 繁体字的 Unicode 区间：\u4E00-\u9FFF（简体和繁体字通用区）之外的区间
      const traditionalChinesePattern = /[\u3400-\u4DBF\u20000-\u2A6DF\u2A700-\u2B73F\u2B740-\u2B81F\u2B820-\u2CEA1\uF900-\uFAFF]/;
      return traditionalChinesePattern.test(str);
    },
    save() {
      // if (this.userInfo.name) {
      //   this.$toast(this.$t("setting.repect"));
      //   return true;
      // }
      if(!this.isTraditionalChinese(this.userInfo.name)){
        this.$toast('請輸入正確的名字');
        return true;
      }
      if (this.name === "" || this.name === null || this.name === undefined) {
        this.$toast.fail(this.$t("setting.name_place"));
        return false;
      }
      this.$http({
        method: 'get',
        data: {name: this.name},
        url: 'user_set_name'
      }).then(res => {
        if (res.code === 200) {
          this.getUserInfo();
          this.name = this.userInfo.name;
          this.$toast(res.msg);
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.name = res.data.name;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({path: '/Login'})
    } else {
      this.getUserInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.van-cell {
  font-size: 35px;
  line-height: 80px;
}

.container p {
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}
</style>
